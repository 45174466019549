<template>
  <div class="superpowers page">
    <div class="main-banner-szuper" ref="scrollHeightBlock">
      <b-container>
        <b-row>
          <b-col cols="12" lg="10" offset-lg="1">
              <div class="superpowers__text">
                <div class="logo-wrap"><img src="@/assets/images/brand/soproni_COLOR_toltsukegyutt_white_text.png" alt="Soproni" class="w-100"></div>                
                <p>Mindannyian különlegesek vagyunk. Koccintsunk azokra, akik mindig kihozzák az asztalnak az első kört, akik bármilyen meccsen beindítják a mexikói hullámot, és akik mindent megtesznek a társaságért! Soproni. Ami közös bennünk.</p>
                <button class="btn--scroll-down"  @click="scrollToNextBlock"></button>
            </div>
          </b-col>
        </b-row>        
      </b-container><!-- /.container -->
    </div>
    <b-container>
      <b-row>
        <b-col cols="12">
            <div class="video-szuper" v-inview="'fade-up'">        
              <Video 
                :staticVideoName="'STA20HEISIM0130.mp4'" 
                :staticVideo="true" 
                :video="null" 
                :needScrollBtn="false" 
                :poster="'szuperkepesseg-1.jpg'"                
              />
          </div>
          <div class="video-szuper" v-inview="'fade-up'">        
              <Video 
                :staticVideoName="'Soproni_30_sor_PontosHang.mp4'" 
                :staticVideo="true" 
                :video="null" 
                :needScrollBtn="false" 
                :poster="'szuperkepesseg-2.jpg'"
              />
          </div>
          <div class="amikozos-logo" v-inview="'fade-up'">            
            <img src="@/assets/images/brand/soproni_amikozos.png" alt="Soproni - ami közös benünk" class="w-100" />            
          </div>          
        </b-col>
      </b-row>
    </b-container>       
  </div>
</template>

<script>
import Video from '@/components/layout/Video.vue'

export default {
  components:{
    Video
  },
  methods:{
    scrollToNextBlock(){            
        let scrollHeight = this.$refs.scrollHeightBlock.clientHeight

        window.scrollTo({
            left: 0, 
            top: scrollHeight,
            behavior: 'smooth'
        });                 
                    
    }
  },
  mounted(){
    this.setMetaTags({
        title: 'Soproni - Szuperképességek', 
        desc: null, 
        keywords: null, 
        ogType: 'website', 
        ogTitle: 'Soproni - Szuperképességek',                         
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc:null
    })
  }
}
</script>